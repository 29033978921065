.app-container {
  display: flex;
  height: 100vh;
}
a{
	text-decoration: none;
}

.left-drawer {
  background-color: #b2cac3;
  padding: 0px;
  overflow-y: hidden;
  width: 20%;

  height: 100%;
  transition: left 0.3s ease; /* Smooth slide-in effect */
}

.left-drawer.open {
  left: 0; /* Show the drawer when open */
  width: 75%; /* Make the drawer 75% width on mobile */
}

.main-content {
  padding: 0px;
  background-color: #ffffff;
  overflow-y: auto;
  flex: 1;
}

.right-helper {
  background-color: #DAEBEE;
  overflow-y: hidden;
  width: 20%;
}

.left-drawer ul {
  list-style: none;
  padding: 0;
}

.left-drawer li {
  margin: 15px 0;
}

.left-drawer a {
  text-decoration: none;
  color: inherit;
}

.feed,
.chat,
.shop {
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
}

.feed:hover,
.chat:hover,
.shop:hover {
  background-color: #f1f2f6;
}

.hamburger {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1001; /* Make sure it stays on top */
  display: none; /* Hide by default */
}

.hamburger div {
  width: 100%;
  height: 2px;
  background-color: #333;
  margin: 6px 0;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Add transition for animation */
}

.hamburger.open div:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger.open div:nth-child(2) {
  opacity: 0;
}

.hamburger.open div:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

@media screen and (max-width: 1200px) {
  .right-helper {
    display: none;
  }

  .left-drawer {
    left: -75%; /* Hide the drawer off-canvas */
    width: 75%; /* Make the drawer 75% width on mobile */
    z-index: 10;
    position: fixed;
    top: 0;
    left: -100%; 
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.15);
  }

  .hamburger {
    display: block; /* Show the hamburger icon */
  }
}
